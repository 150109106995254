import React from 'react';
import GatsbyImage from 'gatsby-image';
import styled from 'styled-components';

const LogoContainerStyles = styled.section`
  margin-top: 75px;
  .logo-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  .supporterImage {
    display: grid;
    height: 150px;
    width: auto;
  }
  h2 {
    font-family: Public_Sans;
    font-weight: 800;
    font-size: clamp(18px, 15vw, 25px);
    margin-bottom: 50px;
    color: var(--darkPurple);

    &:before {
      content: '';
      display: block;
      width: 65px;
      height: 12px;
      background: var(--lightBlue);
      margin-bottom: 20px;
    }
  }
`;

export default function FoundingSupporters({ supporters }) {
  return (
    <LogoContainerStyles>
      <h2>Signatories</h2>
      <div className="logo-grid">
        {supporters
          .sort((a, b) => {
            if (a.title < b.title) {
              return -1;
            }
            if (a.title > b.title) {
              return 1;
            }
            return 0;
          })
          .map((supporter) => (
            <div className="supporterImage" key={supporter.id}>
              <GatsbyImage
                fluid={supporter.logo.asset.fluid}
                alt={supporter.title}
                imgStyle={{ objectFit: 'contain' }}
              />
            </div>
          ))}
      </div>
    </LogoContainerStyles>
  );
}
