import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import GatsbyImage from 'gatsby-image';
import parse from 'html-react-parser';
import whiteLogo from '../assets/images/wwf-onesource-horizontal-logo-reverse-rgb.svg';

const HeaderStyles = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: clamp(400px, 1000px, 50vh);
  background-color: var(--textGrey);
  color: #fff;
  section {
    display: flex;
    height: 100%;
    align-items: center;
    text-align: center;
  }
  h1 {
    font-size: clamp(26px, 4vw, 42px);
    line-height: clamp(30px, 7vw, 70px);
    margin: 0 auto;
    width: 90%;
    padding: 45px 0;
    position: relative;
    text-transform: uppercase;
    font-family: Public_Sans;
    font-weight: 800;
    position: relative;
    z-index: 2;
  }
  .photo-copyright {
    position: absolute;
    bottom: 0;
    left: 1rem;
    z-index: 2;
    font-size: 9px;
  }

  .logo-container {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40vw;
    }
  }
  @media (max-width: 767px) {
    height: 30vh;
    line-height: auto;
  }
`;

const BackgroundImageStyles = styled.div`
  background-size: cover;
  background-position: top;
  display: block;
  height: clamp(400px, 1000px, 50vh);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  margin: 0;
  overflow: hidden;
  @media (max-width: 767px) {
    height: 30vh;
  }
`;

export default function Header({ uniqueHeadline }) {
  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          homepage: allSanityHomepage {
            nodes {
              headline
              image {
                asset {
                  fluid(maxWidth: 1900) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              imageAttribution
              imageAlt
              showLogo
            }
          }
        }
      `}
      render={(data) => {
        const homepage = data.homepage.nodes[0];
        return (
          <HeaderStyles>
            {homepage.image ? (
              <BackgroundImageStyles>
                {homepage.showLogo ? (
                  <div className="logo-container">
                    <img
                      src={whiteLogo}
                      alt="One Source Coalition logo in white"
                    />
                  </div>
                ) : (
                  ''
                )}
                <GatsbyImage
                  fluid={homepage.image.asset.fluid}
                  style={{ position: 'auto' }}
                  alt={homepage.imageAlt}
                />
              </BackgroundImageStyles>
            ) : (
              ''
            )}
            <p className="photo-copyright">
              {parse(homepage.imageAttribution)}
            </p>
          </HeaderStyles>
        );
      }}
    />
  );
}
