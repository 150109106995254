import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import SEO from '../components/SEO';
import Header from '../components/Header';
import FoundingSupporters from '../components/FoundingSupporters';

export default function AboutUs({ location }) {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      aboutSettings: allSanityAbout {
        nodes {
          headline
          body
        }
      }
      supporters: allSanitySupporter(filter: { active: { eq: true } }) {
        nodes {
          id
          title
          logo {
            asset {
              fluid(maxWidth: 300) {
                ...GatsbySanityImageFluid
              }
            }
          }
          active
        }
      }
    }
  `);
  const settings = data.aboutSettings.nodes[0];
  const supporters = data.supporters.nodes;
  return (
    <>
      <SEO location={location} title="About" />

      <Header uniqueHeadline={settings.headline} />
      <main>
        <section className="center">
          <h1>{parse(settings.headline)}</h1>
        </section>
        <p>{parse(settings.body)}</p>
        <FoundingSupporters supporters={supporters} />
      </main>
    </>
  );
}
